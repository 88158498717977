<template>
  <div>
    <van-nav-bar fixed border left-arrow
                 title="发起设备报修"
                 left-text="返回"
                 @click-left="router.back"/>

    <div class="page-container">
      <van-cell-group inset title="客户信息">
        <van-field label="客户姓名" readonly :model-value="device.customerInfo.name"/>
        <van-field label="客户电话" readonly :model-value="device.customerInfo.cellphone"/>
        <van-field label="客户地址" readonly :model-value="device.customerInfo.address"/>
      </van-cell-group>
      <van-cell-group inset title="表单信息">
        <van-form @submit="onSubmit" ref="formRef">
          <van-cell-group inset>
            <van-field label="预约时间"
                       placeholder="请选择预约时间"
                       name="appointmentTime"
                       readonly
                       required
                       clickable
                       @click="form.showPicker = true"
                       v-model="form.appointmentTime"
                       :rules="[{ required: true, message: '请选择预约上门时间' }]"/>

            <van-popup v-model:show="form.showPicker" position="bottom">
              <van-datetime-picker type="datetime"
                                   :min-date="new Date()"
                                   @confirm="onConfirm"
                                   @cancel="form.showPicker = false"/>
            </van-popup>

            <van-field label="问题描述"
                       placeholder="请填写问题描述"
                       v-model.trim="form.description"
                       required
                       autosize
                       clearable
                       show-word-limit
                       type="textarea"
                       maxlength="128"
                       name="description"
                       :rules="[{ required: true, message: '请填写问题描述' }]"/>

            <van-field name="files" label="文件上传">
              <template #input>
                <van-uploader multiple
                              v-model="form.files"
                              :max-count="3"
                              :max-size="1024 * 10 * 1024"
                              @oversize="onOversize"/>
              </template>
            </van-field>

            <van-row>
              <van-col span="16">
                <van-field label="验证码"
                           placeholder="请输入验证码"
                           name="captcha"
                           required
                           clearable
                           v-model.trim="form.captcha"
                           :rules="[{ validator: validationCaptcha, message: '请输入正确内容' }]"/>
              </van-col>
              <van-col span="8">
                <van-image width="108" height="40" :src="captchaUrl" @click="requestCaptcha"/>
              </van-col>
            </van-row>

            <van-cell>
              <van-button round block type="primary" native-type="submit" class="submit" :loading="loading">提交
              </van-button>
            </van-cell>
          </van-cell-group>
        </van-form>
      </van-cell-group>
    </div>
  </div>
</template>

<script setup>
/**
 * 创建待指派工单
 * @description 该类型工单将会在系统中直接生成工单，需要在有设备 ID 的情况下才能发起
 * @author Wayne Carder
 */
import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {Dialog, Toast} from "vant";
import {queryCaptcha, queryDevice} from "@/api/device";
import {isCreate} from "../../../api/workorder";
import axios from "@/plugin/axios";
import {nameEncryption, phoneEncryption} from "../../../plugin/encryption";
import {queryCustomer} from "../../../api/customer";

const router = useRouter();
const formRef = ref(null);
const form = reactive({
  showPicker: false,
  appointmentTime: "",
  description: "",
  files: [],
  captchaId: "",
  captcha: ""
});
const device = reactive({device: {}, customerInfo: {}});

const captchaUrl = ref("");
const errorMessage = ref("");
const query = getUrlParam();
const loading = ref(false);

const onConfirm = (value) => {
  const dateTime = new Date(value);
  let year = dateTime.getFullYear();
  let month = (dateTime.getMonth() + 1).toString().padStart(2, 0);
  let day = dateTime.getDate().toString().padStart(2, 0);
  let hour = dateTime.getHours().toString().padStart(2, 0);
  let minute = dateTime.getMinutes().toString().padStart(2, 0);

  form.appointmentTime = `${year}-${month}-${day} ${hour}:${minute}`;
  form.showPicker = false;
};

const onSubmit = (data) => {
  data.appointmentTime = data.appointmentTime + ":00";
  let formData = new FormData();
  formData.append("appointment", data.appointmentTime);
  formData.append("clientDepartmentId", device.device.customerDepartmentId);
  formData.append("clientId", device.device.customerId);
  formData.append("description", data.description);
  formData.append("logicType", "smp");
  formData.append("origin", "customer");
  formData.append("priority", 5);
  formData.append("resourceId", device.device.id);
  formData.append("resourceName", device.device.name);
  formData.append("resourceType", device.device.deviceType.manufacturer.name);
  formData.append("captchaId", form.captchaId);
  formData.append("captcha", data.captcha);
  formData.append("type", "others-customer");
  if (data.files.length > 0) {
    data.files.forEach(item => formData.append("files", item.file));
  }
  loading.value = true;
  axios.post("/workorder/unassigned-workorder-front", formData).then(response => {
    loading.value = false;
    Dialog.alert({
      title: "提交成功",
      confirmButtonText: "返回首页"
    }).then(() => {
      router.push({path: "/", query: getUrlParam()});
    })
  }).catch(error => {
    loading.value = false;
    console.error("Create workorder fail", error);
    if (error.status === 40000 || error.status === 40400) {
      errorMessage.value = error.data;
      formRef.value.validate("captcha");
      requestCaptcha();
    }
  });
};

const onOversize = () => Toast("文件大小不能超过 10M");

const requestEquipmentInfo = () => {
  queryDevice(getUrlParam().id).then(res => {
    device.device = res.data;
  });
};

const requestCaptcha = () => {
  queryCaptcha().then(res => {
    captchaUrl.value = res.data.base64Image;
    form.captchaId = res.data.captchaId;
    errorMessage.value = "";
  });
};

const isCanCreate = () => {
  isCreate(getUrlParam().id).then(res => {
    console.log("is can create", res);
  }).catch(() => {
    Dialog.alert({
      message: "当前设备正在报修中,是否发起其他设备报修",
      confirmButtonText: "回首页"
    }).then(() => {
      router.push({path: "/navigation", query: getUrlParam()});
    });
  });
};

const requestCustomerInfo = () => {
  const urlParamStr = location.search;
  const urlParams = new URLSearchParams(urlParamStr);
  queryCustomer(urlParams.get("customerId")).then(res => {
    res.data.cellphone = phoneEncryption(res.data.cellphone);
    res.data.name = nameEncryption(res.data.name);
    device.customerInfo = res.data;
  }).catch(err => {
    console.error("Request customer info fail", err);
  });
};

/**
 * 校验验证码
 * @param value 验证码
 */
const validationCaptcha = value => {
  if (!!value) {
    if (!!errorMessage.value) {
      return errorMessage.value;
    } else {
      return true;
    }
  } else {
    return "请输入验证码";
  }
};


onMounted(() => {
  validationUrlParamStrictly();
  isCanCreate();
  requestCustomerInfo();
  requestEquipmentInfo();
  requestCaptcha();
});
</script>

<style scoped>

</style>
