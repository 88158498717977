import axios from "@/plugin/axios";

/**
 * 判断是否可以发起待指派工单
 */
export const isCreate = resourceId => axios.get("/workorder/unassigned-workorder/creatable", {params: {resourceId}});

/**
 * 获取工单列表
 * @param params
 */
export const workorderList = params => axios.get("/workorder/workorders/page", {params});

/**
 * 获取待指派工单列表
 */
export const dispatchWorkorderList = params => axios.get("/workorder/unassigned-workorders/page", {params});

/**
 * 查询待指派工单详情
 * @param id
 */
export const requestDispatchWorkorder = id => axios.get(`/workorder/unassigned-workorder/${id}`);

/**
 * 查询工单详情
 * @param workorderId
 */
export const requestWorkorder = workorderId => axios.get(`/workorder/workorder/${workorderId}`);

/**
 * 根据设备查询未完成的工单
 * @param resourceId
 */
export const requestWorkorderProgressing = resourceId => axios.get(`/workorder/device/${resourceId}/workorder/progressing`);

/**
 * 根据设备查询未指派的工单
 * @param resourceId
 */
export const requestWorkorderUnconverted = resourceId => axios.get(`/workorder/device/${resourceId}/unassigned-workorder/unconverted`);

/**
 * 根据手机号查询报修工单列表
 * @param params  请求参数
 */
export const requestPrepareWorkorder = params => axios.get("/workorder/workorder/prepares/page", {params});

/**
 * 查询准备工单
 * @param id
 */
export const queryPrepareWorkorder = id => axios.get(`/workorder/workorder/prepare/${id}`);

/**
 * 获取回访工单验证码
 */
export const queryFeedbackCaptcha = () => axios.get("/workorder/workorder/feedback/captcha");

/**
 * 回访工单
 * @param data
 * @returns {*}
 */
export const queryFeedback = data => axios.patch("/workorder/workorder/feedback-front", data);

/**
 * 查询指定手机号所有待指派工单
 * @param cellphone
 */
export const queryAllDispatchWorkorder = clientCellphone => axios.get("/workorder/unassigned-workorders", {params: clientCellphone});

/**
 * 查询指定手机号所有准备工单
 * @param cellphone
 */
export const queryAllPrepareWorkorder = clientCellphone => axios.get("/workorder/workorder/prepares", {params: clientCellphone});

export default {
    isCreate,
    workorderList,
    dispatchWorkorderList,
    requestDispatchWorkorder,
    requestWorkorder,
    requestWorkorderUnconverted,
    requestWorkorderProgressing,
    requestPrepareWorkorder,
    queryPrepareWorkorder,
    queryFeedbackCaptcha,
    queryFeedback,
    queryAllDispatchWorkorder,
    queryAllPrepareWorkorder
};
