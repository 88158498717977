import axios from "@/plugin/axios";

/**
 * 查询设备日志
 * @param deviceId 设备 ID
 */
export const queryDeviceLogs = deviceId => axios.get("/resource/device/logs", {params: {deviceId}});

/**
 * 查询设备
 * @param id 设备 ID
 */
export const queryDevice = id => axios.get(`/resource/device/${id}`);
/**
 * 迁移设备
 * */

export const locationDevice = device => axios.patch(`/resource/device/location`, device);

/**
 * 查询设备厂商
 * @param id   厂商 ID
 */
export const queryManufacturer = id => axios.get(`/resource/manufacturer/${id}`);

export const queryCaptcha = ()=> axios.get('/workorder/unassigned-workorder/captcha')


export default {
    queryDeviceLogs, queryDevice, queryManufacturer,queryCaptcha, locationDevice
};
