import axios from "@/plugin/axios";

export const queryCustomersPage = (params) => axios.get("/customer/department/customers", {params});
export const queryCustomer = customerId => axios.get(`/customer/customer/${customerId}`)

export const queryCustomerDepartment = customerDepartmentId => axios.get(`/customer/department/${customerDepartmentId}`);
export const CreateCustomer = data => axios.post(`/customer/customer`, data);
export const updateCustomer = data => axios.patch(`/customer/customer`, data);

export default {
    queryCustomer, queryCustomerDepartment,CreateCustomer,updateCustomer
}
